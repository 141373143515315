<template>
  <div class="container">
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm(1)" />
    <mia-Card title="基本信息">
      <div slot="content">
        <el-row class="head">
          <el-col :span="4">
            <el-col :span="7" class="label">单据编号：</el-col>
            <el-col :span="11">{{ purchaseLedger.orderNo }}</el-col>
          </el-col>
          <el-col :span="4">
            <el-col :span="7" class="label">台账编号：</el-col>
            <el-col :span="11">{{ purchaseLedger.no }}</el-col>
          </el-col>
          <el-col :span="4">
            <el-col :span="7" class="label">业务日期：</el-col>
            <el-col :span="11">{{ purchaseLedger.entryDate }}</el-col>
          </el-col>
          <el-col :span="5">
            <el-col :span="8" class="label">最后修改时间：</el-col>
            <el-col :span="16">{{ purchaseLedger.updateDate }}</el-col>
          </el-col>
          <el-col :span="4">
            <el-col :span="8" class="label">最后修改人：</el-col>
            <el-col :span="9">{{ purchaseLedger.updateUserName }}</el-col>
          </el-col>
        </el-row>
        <el-table
          :data="purchaseLedger.list"
          border
          style="width: 100%"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称">
          </el-table-column>
          <el-table-column prop="productRegCode" label="登记证号" width="180">
          </el-table-column>
          <el-table-column prop="number" label="数量"> </el-table-column>
          <el-table-column prop="specUnit" label="规格单位" width="180">
          </el-table-column>
          <el-table-column prop="use" label="购药用途">
            <template slot-scope="scope">
              <el-input v-if="!isView" v-model="scope.row.use" size="mini" />
              <span v-else>{{ scope.row.use }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="onTick" label="是否挂账">
            <template slot-scope="scope">
              <el-select
                v-if="!isView"
                disabled
                v-model="scope.row.onTick"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in ticks"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <span v-else>{{ scope.row.onTick }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </mia-Card>
    <mia-Card title="购买人信息">
      <div slot="content">
        <el-form ref="basicForm" :model="supplierForm" label-width="170px">
          <el-row>
            <el-col :span="7">
              <el-form-item
                label="购买人"
                prop="customerName"
                key="customerName"
              >
                <el-input
                  v-model="purchaseLedger.customerName"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="联系电话" prop="phone" key="phone">
                <el-input v-model="purchaseLedger.phone" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </mia-Card>
    <mia-Card title="指导/结算信息">
      <div slot="content">
        <el-form ref="basicForm" :model="supplierForm" label-width="170px">
          <el-row>
            <el-col :span="6">
              <el-form-item
                label="是否使用销货软件"
                prop="salesSoftware"
                key="salesSoftware"
              >
                <el-input
                  v-model="purchaseLedger.salesSoftware"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="使用技术指导"
                prop="useTechnology"
                key="useTechnology"
              >
                <el-select
                  :disabled="isView"
                  v-model="purchaseLedger.useTechnology"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option
                    v-for="(item, index) in useTechnologys"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="使用防护指导"
                prop="safetyProtection"
                key="safetyProtection"
              >
                <el-select
                  :disabled="isView"
                  v-model="purchaseLedger.safetyProtection"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option
                    v-for="(item, index) in safetyProtections"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="废弃物回收告知"
                prop="wasteRecovery"
                key="wasteRecovery"
              >
                <el-select
                  :disabled="isView"
                  v-model="purchaseLedger.wasteRecovery"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option
                    v-for="(item, index) in wasteRecoverys"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="结算方式" prop="settlement" key="settlement">
                <el-input
                  v-model="purchaseLedger.settlement"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </mia-Card>
  </div>
</template>
<script>
import UploadImg from "@/components/uploadImg";
import { getSaleLedgerDetails, editSaleLedger } from "@/api/saleLedger";
import settting from "../../../../settting";
export default {
  components: { UploadImg },
  props: {},
  data: () => {
    return {
      purchaseLedger: {},
      imageList: [], // 照片反显
      ledgerId: "",
      supplierForm: {
        supplierName: "",
        supplierAddress: "",
        license: "",
        shipper: "",
        shipperPhone: "",
        legalPerson: "",
        legalPersonPhone: "",
      },
      rules: [],
      ticks: [
        {
          id: "0",
          name: "否",
        },
        {
          id: "1",
          name: "是",
        },
      ],
      useTechnologys: [
        {
          id: "已告知",
          name: "已告知",
        },
        {
          id: "未告知",
          name: "未告知",
        },
      ],
      safetyProtections: [
        {
          id: "已告知",
          name: "已告知",
        },
        {
          id: "未告知",
          name: "未告知",
        },
      ],
      wasteRecoverys: [
        {
          id: "已告知",
          name: "已告知",
        },
        {
          id: "未告知",
          name: "未告知",
        },
      ],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      isView: false,
    };
  },
  mounted() {},
  methods: {
    init(id, isView) {
      this.ledgerId = id;
      this.isView = isView;
      this.getSaleLedgerDetails(id);
    },
    getSaleLedgerDetails(id) {
      getSaleLedgerDetails(id).then((res) => {
        if (res.code === 1) {
          this.purchaseLedger = {
            ...res.data,
            // useTechnology: res.data.useTechnology || "0",
            // safetyProtection: res.data.safetyProtection || "0",
            // wasteRecovery: res.data.wasteRecovery || "0",
          };
          // this.imageList = res.data.billImage.split(",").map((v) => {
          //   return {
          //     src: settting.imgUrl + v,
          //     value: v,
          //   };
          // });
        }
      });
    },
    imgUploadSuc(file, list) {
      const temps = [];
      list.forEach((v) => {
        const picUrl = v.response ? v.response.url : v.value;
        temps.push({
          src: settting.imgUrl + picUrl,
          value: picUrl,
        });
      });
      this.imageList = temps;
    },
    imageRemove(field) {},
    back() {
      this.$emit("back");
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    submitForm() {
      const result = { id: this.ledgerId, ...this.purchaseLedger };
      editSaleLedger(result).then((res) => {
        if (res.code === 1) {
          this.$message.success("提交成功");
          this.$emit("back");
        }
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (column.property == "number") {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          } else {
            sums[index] = "";
          }
        }
      });

      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .head {
    height: 100px;
    line-height: 100px;
    margin-left: 20px;
    color: #666;
    .label {
      color: #333;
    }
  }
}
</style>
