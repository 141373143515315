import request from '@/until/request.js'
const prefixName = '/order/webOrderLedger'
// 退货单
export function getSaleList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
// 检查退单号id
export function getSaleLedgerDetails(id){
  return request({
    url: prefixName + '/details',
    method: 'get',
    params: { id }
  })
}
// 退货单仓库商品列表
export function editSaleLedger(data){
  return request({
    url: prefixName,
    method: 'put',
    data
  })
}

export function saleLedgerExport(id){
  return request({
    url: prefixName+'/export',
    method: 'get',
    responseType: 'blob',
    params: { id }
  })
}